body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  line-height: 1.5;
}

button {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  position: relative;
  color: #00002d;

  min-height: 100vh;

  /* background: #FDFDFD; off white  */
  background: linear-gradient(to bottom right, rgb(252, 252, 253), rgb(245, 250, 255));
}



.Styles {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.Landing,
.About,
.Portfolio,
.Resume {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: stretch;
  justify-content: flex-start;

  /* margin-top: -2vh; */
  /* margin-bottom: -2vh; */
  /* width: calc(1280px - 2vw); */
  min-height: 100vh;
  /* background: #000080; */
}

.Landing {
  justify-content: center;
}

.sectionHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  align-self: stretch;
  justify-self: center;

  padding: 2vh 2vw;
  margin: 1vh 0;

  background: white;
  box-shadow: 0 3px 5px rgba(64, 64, 64, 0.5);

  border-radius: 6px;

  font-weight: bold;
}

.sectionHeader {
  font-size: 3vh;
  font-weight: bolder;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 1vh 0;
  padding: 2vh 2vw;

  background: white;
  box-shadow: 0 3px 5px rgba(64, 64, 64, 0.3);

  border-radius: 6px;
}

.color {
  margin: 16px;
  height: 128px;
  width: 128px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  box-shadow: 0 3px 5px rgba(64, 64, 64, 0.5);
}

.linkDivsGroup {
  display: flex;
  flex-direction: row;
  /* background: aliceblue; */
  padding: 3px 6px;
  border-radius: 12px;
  box-shadow: inset 0 0 3px rgba(64, 64, 64, 0.5);
}

.linkDiv {
  margin: 3px;
  padding: 0 9px;
  height: 3vh;
  background: cornflowerblue;

  color: white;
  text-shadow: 1px 1px 0 black;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 75ms;

  box-shadow: 0 1px 1px rgba(64, 64, 64, 0);
}

.linkDiv:hover {
  transform: scaleX(1.05) scaleY(1.025);
  box-shadow: 0 1px 1px rgba(64, 64, 64, 0.2);
}

.linkDiv:active {
  transform: scale(1.1);
  box-shadow: 0 1px 3px rgba(64, 64, 64, 0.3);
}

.linkDiv:last-of-type {
  border-radius: 0 6px 6px 0;
}

.linkDiv:first-of-type {
  border-radius: 6px 0px 0px 6px;
}

.con {
  max-width: 1268px;
  min-width: 348px;
  width: 70vw;
}

.row,
.navRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

.navRow {
  flex-wrap: nowrap;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}


.displayHeading {
  /* margin-top: 64px; */
  font-size: 96px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 900;
  /* line-height: 1; */
  justify-self: stretch;
  text-align: center;
}

.mainHeading {
  font-size: 32px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 6px 0;
  justify-self: stretch;
  text-align: center;
}

.secondaryHeading {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin: 6px 0;
  font-weight: bold;
}

.regularBody {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 24px 6px;
  font-size: 18px;
}

.landingButton {
  margin: 24px 12px;
  font-size: 16px;
  border: none;
  padding: 12px 9px;
  border-radius: 6px;
  color: black;
  box-shadow: 0 4px 0 rgb(50, 50, 100);
  background: white;
  border: 0.5px solid black;
  transition: 75ms;
}


.landingButton:hover {
  margin-top: 26px;
  margin-bottom: 22px;
  box-shadow: 0 2px 0 rgb(50, 50, 100);
}

.landingButton:active {
  margin-top: 28px;
  margin-bottom: 20px;
  box-shadow: 0 0 0 rgb(50, 50, 100);
}

iframe {
  max-width: 1268px;
  min-width: 348px;
  width: 50vw;
  height: 100vh;
  margin-bottom: 24px;
  border: none;
}

.projectCon {
  max-width: 348px;
  background: white;
  margin: 24px;
  box-shadow: 0 3px 5px gray;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: 75ms;

  color: black;
  text-decoration: none;
}

.projectCon:hover {
  transform: scale(1.025);
}

.projectCon:active {
  transform: scale(1.05);
}
 
.projectImg {
  min-width: 348px;
  width: 15vw;

  border: 0.25px solid #00002d;
  margin-top: 3px;
}

.projectHeading {
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-weight: bold;
}

.skills {
  padding: 0 0;
  background: white;
  max-width: 1268px;
  min-width: 360px;
  width: 50vw;
  box-shadow: 0 3px 5px gray;
  margin-bottom: 48px;
}

.sectionTitle {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-self: stretch;
  padding: 6px 24px;
  background: linear-gradient(to bottom left, rgb(255, 255, 255), rgb(245, 250, 255));
}

.line {
  align-self: stretch;
  height: 1px;
  background: linear-gradient(to right, black 25%, white 75%);
}

p {
  padding: 6px 24px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 12px 24px;
}

.scrollable {
  padding: 12px 24px;
  border-radius: 6px;
  box-shadow: inset 0 0 3px gray;
  margin: 12px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.empty {
  margin : 0 0 0 10px
}